<template>
  <div class="bg-white border bd-e4 px-3">
    <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
    <div class="bg-white pt-3">
      <PersonProfile
        :person="person_disease && person_disease.person"
        :disease="person_disease"
        @showModalAppt="showModalAppt"
        :monitor_stats="monitor_stats"
      ></PersonProfile>
    </div>
    <TypeBar :showing="show" :person_id="person_id" :disease="person_disease" />
    <div class="pb-4" v-if="listHeartRate.length">
      <div class="mb-3 text-center">
        <el-card class="box-card inline-block">
          <div
            class="flex items-center justify-between w-full pb-2 mb-2 border-b-2 border-solid border-t-0 border-l-0 border-r-0 border-gray-200"
          >
            <div class="flex-1 mr-2">
              <div class="flex justify-between text-red-500">
                <div class="mr-1">
                  {{ $t("obj_health_records.lbl_rate_high") }}
                </div>
                <div>{{ highRate }}</div>
              </div>
              <div class="flex justify-between text-blue-500">
                <div class="mr-1">
                  {{ $t("obj_health_records.lbl_rate_low") }}
                </div>
                <div>{{ lowRate }}</div>
              </div>
            </div>
            <div>
              <img src="@/assets/images/heart.gif" alt="Heart Rate" />
            </div>
          </div>
          <div
            class="text-7xl font-bold text-center px-4"
            :class="
              topHeartRate && topHeartRate.heart_rate
                ? getWarningIndex(topHeartRate.heart_rate)
                : ''
            "
          >
            <span class="block leading-none">{{
              topHeartRate && topHeartRate.heart_rate
                ? topHeartRate.heart_rate
                : 0
            }}</span>
          </div>
          <div class="text-center text-xs text-black font-normal">
            {{
              topHeartRate && topHeartRate.date
                ? parseDateTimeAgo(topHeartRate.date * 1000)
                : ""
            }}
          </div>
        </el-card>
      </div>

      <el-card
        class="custom-el-card"
        shadow="hover"
        v-for="item in pagination.data"
        :key="item.date"
      >
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.62 21.3101C12.28 21.4301 11.72 21.4301 11.38 21.3101C8.48 20.3201 2 16.1901 2 9.1901C2 6.1001 4.49 3.6001 7.56 3.6001C9.38 3.6001 10.99 4.4801 12 5.8401C13.01 4.4801 14.63 3.6001 16.44 3.6001C19.51 3.6001 22 6.1001 22 9.1901C22 16.1901 15.52 20.3201 12.62 21.3101Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M2.34595 11.1801H7.96987C8.71987 11.1801 9.55987 11.7501 9.83987 12.4501L10.9799 15.3301C11.2399 15.9801 11.6499 15.9801 11.9099 15.3301L14.1999 9.52011C14.4199 8.96011 14.8299 8.94011 15.1099 9.47011L16.1499 11.4401C16.4599 12.0301 17.2599 12.5101 17.9199 12.5101H21.4999"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <span class="ml-1" :class="getWarningIndex(item.heart_rate)">{{
              item.heart_rate
            }}</span>
          </div>
          <div>{{ appUtils.formatDateTimeFull(item.date * 1000) }}</div>
        </div>
      </el-card>

      <div v-if="pagination && pagination.total > 1">
        <div
          class="flex mt-3 flex-col md:flex-row justify-between items-center gap-2"
        >
          <div class="fs-16">
            {{
              $t("fm_pagination", {
                countItems,
                totalItems: pagination.total,
              })
            }}
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            :pager-count="5"
            :page-size="pagination.page_size"
            :total="pagination.total"
            class="px-0"
            @next-click="handleChangePage"
            @prev-click="handleChangePage"
            @current-change="handleChangePage"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div v-else>
      <ListEmpty
        :title="$t('obj_health_records.lbl_data_not_found')"
        :isAddNewCta="false"
      />
    </div>
  </div>
</template>
<script>
import { AppPagination } from '@/components/Common'
import HeaderWindow from '@/components/Diseases/HeaderWindow.vue'
import PersonProfile from '@/components/Diseases/PersonProfile.vue'
import TypeBar from '@/components/Diseases/TypeBar.vue'
import ListEmpty from '@/components/Empty/ListEmpty.vue'
import appUtils from '@/utils/appUtils'
import { isDev } from '@/utils/constants'
import { debounce } from 'lodash'

export default {
  name: 'HeartRate',
  components: {
    PersonProfile,
    HeaderWindow,
    TypeBar,
    AppPagination,
    ListEmpty
  },
  data () {
    return {
      type: 1,
      show: 'heart-rate',
      title: this.$t('obj_health_records.lbl_health_information'),
      person_id: null,
      person: null,
      disease: null,
      person_disease: {},
      monitor_stats: null,
      iss_add_new: false,
      page: 1,
      total: 0,
      advices: [],
      listHeartRate: [],
      topHeartRate: {},
      appUtils,
      pagination: {
        data: [],
        page_number: 1,
        page_size: 10,
        total: 0
      },
      highRate: 100,
      lowRate: 55
    }
  },
  async mounted () {
    let self = this
    await self.getPersonsDiseasesDetail()
    await self.getStats()
    await self.getHeartRate()
  },
  computed: {
    countItems () {
      return this.page * this.pagination.page_size > this.pagination.total
        ? this.pagination.total
        : this.page * this.pagination.page_size
    }
  },
  watch: {
    '$route.query.page': {
      handler: function (page) {
        this.page = page || 1
        this.getAdvices()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onClose () {
      this.$router.push({
        path: '/doctor/diseases/' + this.$route?.params?.id
      })
    },
    async getPersonsDiseasesDetail () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then((res) => {
            if (res && res.data) {
              self.person_disease = res.data || {}
              self.person_id = self.person_disease.person_id
              self.person = res.data?.person
              self.getAdvices()
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    showModalAppt (is_show) {
      window.$('#modal__appt').modal(is_show ? 'show' : 'hide')
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then((resp) => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    },
    async getAdvices () {
      let self = this
      if (!self.$route?.params?.id) return
      let params = {
        limit: 15,
        page: self.page
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getAdvices(self.$route?.params?.id, params)
        .then((resp) => {
          self.advices = resp?.data
          self.total = resp?.data?.total
        })
    },
    addNew (stt) {
      this.iss_add_new = stt
    },
    async getHeartRate () {
      if (!this.person?.user_id) return

      try {
        const data = {
          // TODO:
          user_id: this.person?.user_id, // this.person?.user_id,
          from: window.moment().startOf('day').unix()
        }
        console.log(data)
        const fakeData = {
          data: {
            code: 200,
            status: 'Ok',
            data: {
              '': {
                status: 0,
                body: {
                  series: {
                    1716850551: {
                      heart_rate: 76,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716850607: {
                      heart_rate: 76,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716851154: {
                      heart_rate: 73,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716851760: {
                      heart_rate: 69,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716852350: {
                      heart_rate: 76,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716852978: {
                      heart_rate: 95,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716853551: {
                      heart_rate: 84,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716853701: {
                      heart_rate: 89,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716854750: {
                      heart_rate: 83,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716855366: {
                      heart_rate: 75,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716855948: {
                      heart_rate: 78,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716856559: {
                      heart_rate: 71,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716857166: {
                      heart_rate: 70,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716857755: {
                      heart_rate: 76,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716858371: {
                      heart_rate: 73,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716858955: {
                      heart_rate: 77,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716859560: {
                      heart_rate: 77,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716860172: {
                      heart_rate: 74,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716860764: {
                      heart_rate: 80,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716861360: {
                      heart_rate: 76,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716861965: {
                      heart_rate: 73,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716862616: {
                      heart_rate: 60,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716863755: {
                      heart_rate: 82,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716864354: {
                      heart_rate: 83,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716865115: {
                      heart_rate: 69,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716865175: {
                      heart_rate: 73,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716865205: {
                      heart_rate: 89,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716865236: {
                      heart_rate: 81,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716865488: {
                      heart_rate: 65,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716865519: {
                      heart_rate: 73,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716865782: {
                      heart_rate: 79,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716865812: {
                      heart_rate: 73,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716866169: {
                      heart_rate: 89,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716866245: {
                      heart_rate: 76,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716866775: {
                      heart_rate: 75,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716867110: {
                      heart_rate: 71,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716867360: {
                      heart_rate: 65,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716867960: {
                      heart_rate: 78,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716868567: {
                      heart_rate: 69,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716869421: {
                      heart_rate: 75,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716869571: {
                      heart_rate: 67,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716869601: {
                      heart_rate: 92,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716869773: {
                      heart_rate: 61,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716870086: {
                      heart_rate: 77,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716870956: {
                      heart_rate: 60,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716871157: {
                      heart_rate: 65,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716871571: {
                      heart_rate: 101,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716872771: {
                      heart_rate: 73,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716873373: {
                      heart_rate: 69,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716873953: {
                      heart_rate: 72,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716874557: {
                      heart_rate: 55,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716875291: {
                      heart_rate: 70,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716875780: {
                      heart_rate: 80,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716876985: {
                      heart_rate: 42,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716877552: {
                      heart_rate: 81,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716878190: {
                      heart_rate: 82,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716878776: {
                      heart_rate: 68,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716879393: {
                      heart_rate: 80,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716879966: {
                      heart_rate: 47,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716881181: {
                      heart_rate: 51,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    },
                    1716881764: {
                      heart_rate: 81,
                      model: 'ScanWatch 2',
                      model_id: 94,
                      deviceid: '1c2bdf4c3a6933240f28d3696f871bdb4dd3b9c9'
                    }
                  }
                },
                error: ''
              }
            }
          }
        }
        const res = isDev
          ? fakeData
          : await this.$rf.getRequest('HIDRequest').getHeartRate(data)

        if (!res) return
        const rs = Object.values(res?.data?.data)[0]?.body?.series || {}
        const dataArray = Object.entries(rs)
          .map(([key, value]) => ({
            date: Number(key),
            ...value
          }))
          ?.reverse()
        this.topHeartRate = dataArray?.length ? dataArray[0] : {}
        this.listHeartRate = [...dataArray].slice(1)
        console.log(this.listHeartRate)
        this.pagination = {
          ...this.pagination,
          data: this.listHeartRate.slice(0, this.pagination.page_size),
          total: this.listHeartRate.length
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleChangePage: debounce(function (newPage) {
      this.page = newPage
      this.pagination = {
        ...this.pagination,
        data: this.listHeartRate.slice(
          (newPage - 1) * this.pagination.page_size,
          newPage * this.pagination.page_size
        )
      }
    }, 500),
    getWarningIndex (data) {
      if (!data) return ''
      if (data >= this.highRate) {
        return 'text-red-500 font-bold'
      }
      if (data <= this.lowRate) {
        return 'text-blue-500 font-bold'
      }
      return 'text-black'
    },
    parseDateTimeAgo (value) {
      const lang = this.$lang !== 'jp' ? this.$lang : 'ja'
      return value ? window.moment(value).locale(lang).fromNow() : ''
    }
  }
}
</script>
<style lang="css" scoped>
.el-card {
  position: relative;
  z-index: 1;
}
.el-card.is-hover-shadow:hover,
.el-card.is-hover-shadow:focus {
  z-index: 2;
}
.el-card.custom-el-card /deep/ .el-card__body {
  padding: 10px 20px;
}
</style>
